import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

// import logoBranca from "../images/logo-branca.svg"
import logoBrancaPng from "../images/logo-branca-img.png"

const HeaderIndex = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  function handleMenuToggle() {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    } else {
      setIsMenuOpen(true)
    }
  }

  return (
    <Wrapper>
      <Container>
        <div className="logo">
          <h1>
            <Link to="/">
              <img src={logoBrancaPng} alt="Logo da Zoombe" />
            </Link>
          </h1>
        </div>
        <div className="menu">
          <button type="button" onClick={handleMenuToggle}>
            <div
              className={`button_container ${isMenuOpen ? "active" : null}`}
              id="toggle"
            >
              <span className="top"></span>
              <span className="middle"></span>
              <span className="bottom"></span>
            </div>
          </button>
          <div className={`overlay ${isMenuOpen ? "open" : null}`} id="overlay">
            <nav className="overlay-menu">
              <ul>
                <li>
                  <Link to="/">início</Link>
                </li>
                <li>
                  <Link to="/a-empresa">a empresa</Link>
                </li>
                <li>
                  <Link to="/solucoes">soluções</Link>
                </li>
                <li>
                  <Link to="/estudio">o estúdio</Link>
                </li>
                <li>
                  <Link to="/portfolio">portfólio</Link>
                </li>
                <li>
                  <Link to="/contato">contato</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 9999;

  @media (max-width: 499px) {
    padding-left: 20px;
    padding-right: 20px;

    .button_container {
      margin-bottom: 8px;
    }
  }

  background: none;

  max-width: 1200px;
  padding: 0 20px;

  font-family: "CircularStd";
  font-weight: bold;
  font-size: 57px;
  line-height: 58px;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, width 0.35s;
    z-index: 9998;

    &:before {
      content: "";
      background: #212121;
      left: -65%;
      top: 0;
      width: 50%;
      height: 100%;
      position: absolute;
      transition: left 0.35s ease;
    }

    &:after {
      content: "";
      background: #212121;
      right: -55%;
      top: 0;
      width: 50%;
      height: 100%;
      position: absolute;
      transition: all 0.35s ease;
    }

    &.open {
      opacity: 1;
      visibility: visible;
      height: 100%;

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }

      li {
        animation: fadeInRight 0.5s ease forwards;
        animation-delay: 0.35s;

        &:nth-of-type(2) {
          animation-delay: 0.45s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.55s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.65s;
        }
      }
    }
    nav {
      position: relative;
      height: 70%;
      top: 50%;
      transform: translateY(-50%);
      font-size: 50px;
      font-family: "Vollkorn", serif;
      font-weight: 400;
      text-align: center;
      z-index: 9999;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%;

      li {
        display: block;
        height: 22%;
        height: calc(100% / 6);
        min-height: 50px;
        position: relative;
        opacity: 0;

        a {
          display: block;
          position: relative;
          color: #f5f5f5;
          text-decoration: none;
          overflow: hidden;

          &:hover:after,
          &:focus:after,
          &:active:after {
            width: 100%;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0%;
            transform: translateX(-50%);
            height: 3px;
            background: #f44336;
            transition: 0.35s;
          }
        }
      }
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }

  .button_container {
    position: relative;
    top: 5%;
    right: 20%;
    max-width: 35px;
    height: 27px;
    width: 35px;
    cursor: pointer;
    z-index: 9999;
    transition: opacity 0.25s ease;

    &:hover {
      opacity: 0.7;
    }

    &.active {
      .top {
        transform: translateY(10px) translateX(0) rotate(45deg);
        background: white;
      }
      .middle {
        opacity: 0;
        background: white;
      }

      .bottom {
        transform: translateY(-10px) translateX(0) rotate(-45deg);
        background: white;
      }
    }

    span {
      background: #f5f5f5;
      border: none;
      height: 5px;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0;
      transition: all 0.35s ease;
      cursor: pointer;

      &:nth-of-type(2) {
        top: 10px;
      }

      &:nth-of-type(3) {
        top: 20px;
      }
    }
  }

  div.logo {
    height: fit-content;
    img {
      width: 263px;
    }
  }
  div.menu {
    img {
      width: 40px;
    }
  }

  button {
    background: none;
    border: none;
    height: fit-content;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    font-size: 3rem;
    line-height: 3rem;
    ul.menu-content {
      padding-right: 20px;
    }
    div.menu-overlay {
      transition: 0.1s;
    }

    div.button_container {
      right: 0;
      top: 7px;
    }
  }

  @media (max-width: 499px) {
    div.logo {
      height: fit-content;
      img {
        width: 253px;
      }
    }
  }

  @media (max-width: 320px) {
    div.logo {
      img {
        width: 219px;
      }
    }
  }
`

export default HeaderIndex
