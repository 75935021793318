import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import arrowRightLight from "../images/icons/arrow-right.svg"
import arrowRightDark from "../images/icons/arrow-right-black.svg"
export default function FaleComAGente({ darkmode }) {
  return (
    <Container style={{ backgroundColor: darkmode ? "#212121" : "#ebebeb" }}>
      <Wrapper>
        <Link to="/contato" style={{ color: darkmode ? "#ffffff" : "#212121" }}>
          fale com a gente
        </Link>
        <img
          src={darkmode ? arrowRightLight : arrowRightDark}
          alt="Seta apontando para a direita"
        />
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;

  img {
    cursor: pointer;
  }

  a {
    font-size: 30px;
    text-decoration: none;
    padding-right: 20px;
    &:hover {
      color: #f44336 !important;
    }
  }
`
