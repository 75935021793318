import React from "react"

import { Link } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import MenuSolucoesHome from "../components/Home/menu-solucoes-home"
import FaleComAGente from "../components/fale-com-a-gente"

import BackgroundSection from "../components/Home/BackgroundSection"
import BackgroundSectionMobile from "../components/Home/BackgroundSectionMobile"
import BackgroundSection2 from "../components/Home/BackgroundSection2"
import BackgroundSection2Mobile from "../components/Home/BackgroundSection2Mobile"
import BackgroundSection3 from "../components/Home/BackgroundSection3"
import BackgroundSection4 from "../components/Home/BackgroundSection4"
import ImageOne from "../components/Home/ImageOne"
import HeaderIndex from "../components/headerIndex"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Container>
      <HeaderIndex />
      <SEO title="A zoombe" />
      <SectionOne>
        <BackgroundSection />
        <BackgroundSectionMobile />
      </SectionOne>
      <SectionFour>
        <BackgroundSection3 />
      </SectionFour>
      <SectionTwo>
        <BackgroundSection2 />
        <BackgroundSection2Mobile />
      </SectionTwo>
      <Wrapper>
        <SectionThree>
          <div className="left">
            <Link to="/solucoes">
              <h2>soluções</h2>
            </Link>
            <MenuSolucoesHome />
          </div>
          <div className="right">
            <ImageOne className="gatsby-image-wrapper" />
          </div>
        </SectionThree>
      </Wrapper>
      <SectionBancoDoBrasil>
        <BackgroundSection4 />
      </SectionBancoDoBrasil>
      <SectionFive>
        <FaleComAGente />
      </SectionFive>
    </Container>
  </Layout>
)

const Container = styled.div`
  padding-top: 0px;
  margin-top: -26px;
  width: 100%;
  overflow-x: hidden;
`
const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  background-color: #fff;
`
const SectionOne = styled.section`
  height: 100vh;
  min-height: 100vh;
  height: fit-content;
`
const SectionTwo = styled.section`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
`
const SectionThree = styled.section`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  padding: 20px 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
  }

  h2 {
    color: #f44336;
    font-weight: lighter;
    font-size: 68px;
    margin-bottom: 20px;
  }

  div.right {
    .gatsby-image-wrapper {
      width: 500px;
    }
  }

  @media (max-width: 1020px) {
    padding-top: 20px;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;

    padding: 40px 0px;

    div.right {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 40px 40px 20px 0px;

      .gatsby-image-wrapper {
        align-self: center;
        width: 320px;
      }
    }
  }

  @media (max-width: 400px) {
    div.right {
      padding: 20px 0 0 0;
      .gatsby-image-wrapper {
        align-self: center;
        width: 400px;
      }
    }
  }
`

const SectionBancoDoBrasil = styled.section`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
`

const SectionFour = styled.section`
  width: 100%;
  height: 100vh;
`

const SectionFive = styled.section`
  height: fit-content;
  background-color: tomato;
`
export default IndexPage
