import React from "react"

import { Link } from "gatsby"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import arrowRightLight from "../../images/icons/arrow-right.svg"

import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "home/4.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // Set ImageData.
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#040e18`}
          >
            <div className="info">
              <div>
                <Link to="/estudio">o estúdio</Link>
              </div>
              <div>
                <img src={arrowRightLight} alt="Ir até página do estúdio" />
              </div>
            </div>
          </BackgroundImage>
        )
      }}
    />
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  height: 100vh;
  background-position: bottom left;
  background-repeat: repeat-y;
  background-size: cover;

  .bottom-right {
    position: relative;
    bottom: 10%;
  }

  div.info {
    height: 100vh;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    padding-bottom: 60px;
    padding-right: 20px;

    a {
      padding-right: 10px;
      font-size: 40px;

      text-decoration: none;
      color: #ffffff;
      &:hover {
        color: #f44336;
        cursor: pointer;
      }
    }

    img {
      width: 30px;
      &:hover {
        cursor: pointer;
      }

      position: relative;
      top: 5px;
    }
  }
`

export default StyledBackgroundSection
